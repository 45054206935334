import NumberFormat from 'react-number-format'
import { useContext } from 'react'
import { LifeExpectancy } from '../../App.js'
import { ageInYears } from '../../utils/utils.js'
import LifeEstimate from './LifeEstimate.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
export default function Years({ birthdate }) {
  const lifespan = useContext(LifeExpectancy)
  const yearsLived = ageInYears(birthdate)
  const yearsToLive = lifespan - ageInYears(birthdate)

  return (
    <div>
      <div className="flex">
        <LifeEstimate birthdate={birthdate} />
      </div>
      <div className="flex">
        <h2 className="text-gray-700">
          You've lived
          <NumberFormat
            value={yearsLived}
            displayType="text"
            thousandSeparator={true}
            suffix=" "
            prefix=" "
          />
          Years so far.
        </h2>
      </div>
      <div className="flex">
        <h2 className="text-gray-700">
          <NumberFormat
            value={yearsToLive}
            displayType="text"
            thousandSeparator={true}
            suffix=" "
            prefix=" "
          />
          Years yet to live; so make the most of it!
        </h2>
      </div>
      <FontAwesomeIcon
        icon={solid('star')}
        className="m-3"
        size="2x"
        style={{ color: 'tomato' }}
      />
      {Array.from(Array(yearsLived - 1)).map((icon, index) => (
        <FontAwesomeIcon
          icon={solid('square-full')}
          size="2x"
          key={index}
          className="m-3 text-gray-300"
        />
      ))}
      <FontAwesomeIcon
        icon={solid('gift')}
        className="m-3"
        size="2x"
        style={{ color: 'tomato' }}
      />
      {Array.from(Array(yearsToLive - 2)).map((icon, index) => (
        <FontAwesomeIcon
          icon={solid('square-full')}
          className="m-3"
          size="2x"
          key={index}
          style={{ color: 'tomato' }}
        />
      ))}
      <FontAwesomeIcon
        icon={regular('face-dizzy')}
        className="m-3"
        size="2x"
        style={{ color: 'tomato' }}
      />
    </div>
  )
}
