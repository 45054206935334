import Years from './timeline/Years'
import Months from './timeline/Months'
import Weeks from './timeline/Weeks'
import Days from './timeline/Days'
import Links from './timeline/Links'
export default function Calendar({ birthdate, currentTab, handleIsLoading }) {
  // -> /images/backgrounds/road-tree.jpg

  return (
    <main>
      <div
        className="min-h-screen flex items-start justify-center pt-10 contrast-75"
        style={{
          backgroundImage: 'url()',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="w-full pl-10 pr-10">
          <div className="flex justify-center pb-5">
            <h1>Welcome to Our Life Calendar</h1>
          </div>
          <div>
            {currentTab === 'Years' ? <Years birthdate={birthdate} /> : ''}
            {currentTab === 'Months' ? <Months birthdate={birthdate} /> : ''}
            {currentTab === 'Weeks' ? <Weeks birthdate={birthdate} /> : ''}
            {currentTab === 'Days' ? <Days birthdate={birthdate} /> : ''}
            {currentTab === 'Links' ? <Links /> : ''}
          </div>
        </div>
      </div>
    </main>
  )
}
