import { useState, createContext, useEffect } from 'react'
import Header from './components/Header'
import Home from './components/Home'
import Calendar from './components/Calendar'
import Footer from './components/Footer'
import './App.css'
import { actuaryTable } from './data/actuary'
import { ageInYears } from './utils/utils'
export const LifeExpectancy = createContext()

function App() {
  const [birthdate, setBirthdate] = useState(localStorage.getItem('birthdate'))
  const [lifespan, setLifeSpan] = useState(localStorage.getItem('lifespan'))
  const [currentTab, setCurrentTab] = useState(
    localStorage.getItem('currentTab') || 'Weeks'
  )

  const handleGoClick = (bday) => {
    setBirthdate(bday)
    localStorage.setItem('birthdate', bday)
    calculateLifeSpan(bday)
  }

  const handleTabChange = (currTab) => {
    setCurrentTab(currTab)
    localStorage.setItem('currentTab', currTab)
  }

  const calculateLifeSpan = (bday) => {
    const age = ageInYears(bday)

    const currentAgeObj = actuaryTable.filter((obj) => {
      if (obj.age === age) {
        return true // Math.ceil((obj.male + obj.female) / 2)
      }
      return false
    })

    const lifespan =
      age + Math.ceil((currentAgeObj[0].male + currentAgeObj[0].female) / 2)

    setLifeSpan(lifespan)

    localStorage.setItem('lifespan', lifespan)
  }

  useEffect(() => {
    if (birthdate) calculateLifeSpan(birthdate)
  }, [birthdate])

  return (
    <LifeExpectancy.Provider value={lifespan}>
      <div id="app" className="flex flex-col h-screen">
        <Header
          handleTabChange={handleTabChange}
          currentTab={currentTab}
          birthdate={birthdate}
        />
        {!birthdate ? (
          <Home handleGoClick={handleGoClick} />
        ) : (
          <>
            <Calendar currentTab={currentTab} birthdate={birthdate} />
          </>
        )}
        <Footer />
      </div>
    </LifeExpectancy.Provider>
  )
}

export default App
