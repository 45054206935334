import { Temporal, toTemporalInstant } from '@js-temporal/polyfill'

Date.prototype.toTemporalInstant = toTemporalInstant

export const validateBirthdate = (birthdate) => {
  const validLength = birthdate && birthdate.length === 10 ? true : false
  const validAge =
    ageInYears(birthdate) > 1 && ageInYears(birthdate) <= 112 ? true : false
  return validLength && validAge ? true : false
}

export const getToday = () => {
  const today = Temporal.Now.plainDateISO()
  return today
}

export const yearToWeeks = (year) => {
  return year * 52.143
}

export const ageInYears = (birthdate) => {
  const today = Temporal.Now.plainDateISO()
  const bday = Temporal.PlainDate.from(birthdate)
  const until = bday.until(today, { largestUnit: 'years' })
  return until.years
}

export const ageInMonths = (birthdate) => {
  const today = Temporal.Now.plainDateISO()
  const bday = Temporal.PlainDate.from(birthdate)
  const until = bday.until(today, { largestUnit: 'months' })
  return until.months
}

export const ageInWeeks = (birthdate) => {
  const today = Temporal.Now.plainDateISO()
  const bday = Temporal.PlainDate.from(birthdate)
  const until = bday.until(today, { largestUnit: 'weeks' })
  return until.weeks
}

export const ageInDays = (birthdate) => {
  const today = Temporal.Now.plainDateISO()
  const bday = Temporal.PlainDate.from(birthdate)
  const until = bday.until(today, { largestUnit: 'day' })
  return until.days
}

export const ageInMinutes = (birthdate) => {
  const today = Temporal.Now.plainDateISO()
  const bday = Temporal.PlainDate.from(birthdate)
  const until = bday.until(today, { largestUnit: 'days' })
  return until.days * 1440
}

export const ageInSeconds = (birthdate) => {
  const today = Temporal.Now.plainDateISO()
  const bday = Temporal.PlainDate.from(birthdate)
  const until = bday.until(today, { largestUnit: 'days' })
  return until.days * 86400
}

export const dateOfDeath = (date, yearsToAdd) => {
  if (!date) return
  const today = Temporal.PlainDate.from(date)
  const death = today.add({ years: yearsToAdd })
  return death
}

export const daysUntilDeath = (birthdate, yearsToAdd) => {
  const today = Temporal.Now.plainDateISO()
  const bday = Temporal.PlainDate.from(birthdate)
  const dod = dateOfDeath(bday, yearsToAdd)
  const until = today.until(dod, { largestUnit: 'day' })
  return until.days
}

export const weeksUntilDeath = (birthdate, yearsToAdd) => {
  const today = Temporal.Now.plainDateISO()
  const bday = Temporal.PlainDate.from(birthdate)
  const dod = dateOfDeath(bday, yearsToAdd)
  const until = today.until(dod, { largestUnit: 'week' })
  return until.weeks
}

export const monthsUntilDeath = (birthdate, yearsToAdd) => {
  const today = Temporal.Now.plainDateISO()
  const bday = Temporal.PlainDate.from(birthdate)
  const dod = dateOfDeath(bday, yearsToAdd)
  const until = today.until(dod, { largestUnit: 'months' })
  return until.months
}
