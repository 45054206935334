import { useContext } from 'react'
import { ageInDays, daysUntilDeath } from '../../utils/utils.js'
import LifeEstimate from './LifeEstimate.js'
import NumberFormat from 'react-number-format'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LifeExpectancy } from '../../App.js'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
export default function Days({ birthdate }) {
  const lifespan = useContext(LifeExpectancy)
  const daysLived = ageInDays(birthdate)
  const daysLeftToLive = daysUntilDeath(birthdate, lifespan)
  return (
    <div className="">
      <div className="flex">
        <LifeEstimate birthdate={birthdate} />
      </div>
      <div className="flex">
        <h2 className="text-gray-700">
          You've lived
          <NumberFormat
            value={daysLived}
            displayType="text"
            thousandSeparator={true}
            suffix=" "
            prefix=" "
          />
          Days so far.
        </h2>
      </div>
      <div className="flex">
        <h2 className="text-gray-700">
          You have
          <NumberFormat
            value={daysLeftToLive}
            displayType="text"
            thousandSeparator={true}
            suffix=" "
            prefix=" "
          />
          Days left to Live, so make the most of it!
        </h2>
      </div>
      <div className="">
        <FontAwesomeIcon
          icon={solid('star')}
          size="2xs"
          style={{ color: 'tomato' }}
          className="p-[1px]"
        />
        {Array.from(Array(daysLived - 1)).map((icon, index) => (
          <FontAwesomeIcon
            icon={solid('square-full')}
            className="text-gray-300 p-[1px]"
            size="2xs"
            key={index}
          />
        ))}
        <FontAwesomeIcon
          icon={solid('gift')}
          size="2xs"
          style={{ color: 'tomato' }}
          className="p-[1px]"
        />
        {/* {Array.from(Array(daysLeftToLive - 2)).map((icon, index) => (
          <FontAwesomeIcon
            icon={solid('square-full')}
            size="2xs"
            key={index}
            style={{ color: 'tomato' }}
            className="p-[1px]"
          />
        ))} */}
        {Array.from(Array(daysLeftToLive - 2)).map((icon, index) => (
          <FontAwesomeIcon
            icon={solid('square-full')}
            size="2xs"
            key={index}
            style={{ color: 'tomato' }}
            className="p-[1px]"
          />
        ))}
        <FontAwesomeIcon
          icon={regular('face-dizzy')}
          size="2xs"
          style={{ color: 'tomato' }}
          className="p-[1px]"
        />
      </div>
    </div>
  )
}
