import { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { LifeExpectancy } from '../App.js'
import { getToday } from '../utils/utils.js'
export default function Header({ birthdate, currentTab, handleTabChange }) {
  const lifespan = useContext(LifeExpectancy)
  const today = getToday().toString()

  const tabs = [
    { name: 'Days', href: '#', current: false },
    { name: 'Weeks', href: '#', current: false },
    { name: 'Months', href: '#', current: false },
    { name: 'Years', href: '#', current: false },
  ]

  tabs.find((o) => {
    if (o.name === currentTab) {
      o.current = true
      return true
    }
    return false
  })

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  // Use an "onChange" listener on #tabs to redirect the user to the selected tab URL.
  return (
    <header>
      <div className="w-full bg-pine shadow-lg pin-t">
        <div className="py-2 pl-8">
          <div className="flex flex-wrap justify-between">
            <div className="w-1/4 self-center">
              <a href="/">
                <img
                  src="/images/ourlifecalendar.png"
                  alt="Our life calendar"
                  className="w-32"
                />
              </a>
            </div>
            {birthdate && (
              <>
                <div className="header-tabs w-1/3 sm:w-1/3 md:w-1/3 lg:w-1/3 self-center">
                  <div>
                    <div className="sm:hidden">
                      <label htmlFor="tabs" className="sr-only">
                        Select a tab
                      </label>

                      <select
                        id="tabs"
                        name="tabs"
                        className="block w-full focus:ring-emerald-500 focus:border-emerald-500 border-gray-300 rounded-md"
                        defaultValue={tabs.find((tab) => tab.current).name}
                        onChange={(e) => {
                          handleTabChange(e.target.value)
                        }}
                      >
                        {tabs.map((tab) => (
                          <option key={tab.name}>{tab.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="hidden sm:block">
                      <div className="border-b border-gray-200">
                        <nav className="-mb-px flex" aria-label="Tabs">
                          {tabs.map((tab) => (
                            <a
                              key={tab.name}
                              href={tab.href}
                              onClick={(e) => {
                                handleTabChange(e.target.innerText)
                              }}
                              className={classNames(
                                tab.current
                                  ? 'border-emerald-500 text-emerald-600'
                                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                'w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm'
                              )}
                              aria-current={tab.current ? 'page' : undefined}
                            >
                              {tab.name}
                            </a>
                          ))}
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-1/4 self-center text-right pr-8">
                  <div className="inline">
                    <div className="float-right">
                      <h3>
                        <FontAwesomeIcon
                          icon={solid('user')}
                          className="mr-1"
                          size="lg"
                          style={{ color: 'tomato' }}
                        />
                      </h3>
                      <h3>Lifepan: {lifespan}</h3>
                      <h3 className="text-purple">Date: {today}</h3>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}
