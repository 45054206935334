import { useContext } from 'react'
import { ageInMonths, monthsUntilDeath } from '../../utils/utils.js'
import NumberFormat from 'react-number-format'
import { LifeExpectancy } from '../../App.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import LifeEstimate from './LifeEstimate.js'

export default function Months({ birthdate }) {
  const lifespan = useContext(LifeExpectancy)
  const monthsLived = ageInMonths(birthdate)
  const monthsToLive = monthsUntilDeath(birthdate, lifespan)

  return (
    <>
      <div className="flex">
        <LifeEstimate birthdate={birthdate} />
      </div>
      <div className="flex">
        <h2 className="text-gray-700">
          You've lived
          <NumberFormat
            value={monthsLived}
            displayType="text"
            thousandSeparator={true}
            suffix=" "
            prefix=" "
          />
          Months so far.
        </h2>
      </div>
      <div className="flex">
        <h2>
          <NumberFormat
            value={monthsToLive}
            displayType="text"
            thousandSeparator={true}
            suffix=" "
            prefix=" "
          />
          Months yet to live; so make the most of it!
        </h2>
      </div>
      <div className="">
        <FontAwesomeIcon
          icon={solid('star')}
          className="mr-2"
          size="lg"
          style={{ color: 'tomato' }}
        />
        {Array.from(Array(monthsLived - 1)).map((icon, index) => (
          <FontAwesomeIcon
            icon={solid('square-full')}
            size="lg"
            key={index}
            className="mr-2 text-gray-300"
          />
        ))}
        <FontAwesomeIcon
          icon={solid('gift')}
          className="mr-2"
          size="lg"
          style={{ color: 'tomato' }}
        />
        {Array.from(Array(monthsToLive - 2)).map((icon, index) => (
          <FontAwesomeIcon
            icon={solid('square-full')}
            className="mr-2"
            size="lg"
            key={index}
            style={{ color: 'tomato' }}
          />
        ))}
        <FontAwesomeIcon
          icon={regular('face-dizzy')}
          className="mr-2"
          size="lg"
          style={{ color: 'tomato' }}
        />
      </div>
    </>
  )
}
