// https://www.ssa.gov/oact/STATS/table4c6_2017.html
export const actuaryTable = [
  {
    age: 0,
    male: 75.97,
    female: 80.96,
  },
  {
    age: 1,
    male: 75.97,
    female: 80.39,
  },
  {
    age: 2,
    male: 75.45,
    female: 79.42,
  },
  {
    age: 3,
    male: 73.5,
    female: 78.43,
  },
  {
    age: 4,
    male: 72.52,
    female: 77.45,
  },
  {
    age: 5,
    male: 71.53,
    female: 76.46,
  },
  {
    age: 6,
    male: 70.54,
    female: 75.47,
  },
  {
    age: 7,
    male: 69.55,
    female: 74.47,
  },
  {
    age: 8,
    male: 68.56,
    female: 73.48,
  },
  {
    age: 9,
    male: 67.57,
    female: 72.49,
  },
  {
    age: 10,
    male: 66.57,
    female: 71.5,
  },
  {
    age: 11,
    male: 65.58,
    female: 70.5,
  },
  {
    age: 12,
    male: 64.59,
    female: 69.51,
  },
  {
    age: 13,
    male: 63.6,
    female: 68.52,
  },
  {
    age: 14,
    male: 62.61,
    female: 67.53,
  },
  {
    age: 15,
    male: 61.63,
    female: 66.54,
  },
  {
    age: 16,
    male: 60.66,
    female: 65.55,
  },
  {
    age: 17,
    male: 59.7,
    female: 64.57,
  },
  {
    age: 18,
    male: 58.74,
    female: 63.59,
  },
  {
    age: 19,
    male: 57.79,
    female: 62.61,
  },
  {
    age: 20,
    male: 56.85,
    female: 61.63,
  },
  {
    age: 21,
    male: 55.91,
    female: 60.66,
  },
  {
    age: 22,
    male: 54.98,
    female: 59.69,
  },
  {
    age: 23,
    male: 54.06,
    female: 58.72,
  },
  {
    age: 24,
    male: 53.14,
    female: 57.75,
  },
  {
    age: 25,
    male: 52.22,
    female: 56.78,
  },
  {
    age: 26,
    male: 51.31,
    female: 55.82,
  },
  {
    age: 27,
    male: 50.39,
    female: 54.85,
  },
  {
    age: 28,
    male: 49.48,
    female: 53.89,
  },
  {
    age: 29,
    male: 48.56,
    female: 52.93,
  },
  {
    age: 30,
    male: 47.65,
    female: 51.97,
  },
  {
    age: 31,
    male: 46.74,
    female: 51.01,
  },
  {
    age: 32,
    male: 45.83,
    female: 50.06,
  },
  {
    age: 33,
    male: 44.92,
    female: 49.1,
  },
  {
    age: 34,
    male: 44.01,
    female: 48.15,
  },
  {
    age: 35,
    male: 43.1,
    female: 47.2,
  },
  {
    age: 36,
    male: 42.19,
    female: 46.25,
  },
  {
    age: 37,
    male: 41.28,
    female: 45.3,
  },
  {
    age: 38,
    male: 40.37,
    female: 44.36,
  },
  {
    age: 39,
    male: 39.47,
    female: 43.41,
  },
  {
    age: 40,
    male: 38.56,
    female: 42.47,
  },
  {
    age: 41,
    male: 37.65,
    female: 41.53,
  },
  {
    age: 42,
    male: 36.75,
    female: 40.59,
  },
  {
    age: 43,
    male: 35.85,
    female: 39.66,
  },
  {
    age: 44,
    male: 34.95,
    female: 38.73,
  },
  {
    age: 45,
    male: 34.06,
    female: 37.8,
  },
  {
    age: 46,
    male: 33.17,
    female: 36.88,
  },
  {
    age: 47,
    male: 32.28,
    female: 35.96,
  },
  {
    age: 48,
    male: 31.41,
    female: 35.04,
  },
  {
    age: 49,
    male: 30.54,
    female: 34.13,
  },
  {
    age: 50,
    male: 29.67,
    female: 33.23,
  },
  {
    age: 51,
    male: 28.82,
    female: 32.33,
  },
  {
    age: 52,
    male: 27.98,
    female: 31.44,
  },
  {
    age: 53,
    male: 27.14,
    female: 30.55,
  },
  {
    age: 54,
    male: 26.32,
    female: 29.68,
  },
  {
    age: 55,
    male: 25.5,
    female: 28.81,
  },
  {
    age: 56,
    male: 24.7,
    female: 27.94,
  },
  {
    age: 57,
    male: 23.9,
    female: 27.09,
  },
  {
    age: 58,
    male: 23.12,
    female: 26.24,
  },
  {
    age: 59,
    male: 22.34,
    female: 25.39,
  },
  {
    age: 60,
    male: 21.58,
    female: 22.56,
  },
  {
    age: 61,
    male: 20.83,
    female: 23.72,
  },
  {
    age: 62,
    male: 20.08,
    female: 22.9,
  },
  {
    age: 63,
    male: 19.35,
    female: 22.07,
  },
  {
    age: 64,
    male: 18.62,
    female: 21.26,
  },
  {
    age: 65,
    male: 17.89,
    female: 20.45,
  },
  {
    age: 66,
    male: 17.18,
    female: 19.65,
  },
  {
    age: 67,
    male: 16.47,
    female: 18.86,
  },
  {
    age: 68,
    male: 15.77,
    female: 18.07,
  },
  {
    age: 69,
    male: 15.07,
    female: 17.3,
  },
  {
    age: 70,
    male: 14.39,
    female: 16.54,
  },
  {
    age: 71,
    male: 13.71,
    female: 15.79,
  },
  {
    age: 72,
    male: 13.05,
    female: 15.05,
  },
  {
    age: 73,
    male: 12.4,
    female: 14.32,
  },
  {
    age: 74,
    male: 11.76,
    female: 13.61,
  },
  {
    age: 75,
    male: 11.14,
    female: 12.92,
  },
  {
    age: 76,
    male: 10.53,
    female: 12.23,
  },
  {
    age: 77,
    male: 9.94,
    female: 11.57,
  },
  {
    age: 78,
    male: 9.32,
    female: 10.92,
  },
  {
    age: 79,
    male: 8.82,
    female: 10.29,
  },
  {
    age: 80,
    male: 8.28,
    female: 9.68,
  },
  {
    age: 81,
    male: 7.76,
    female: 9.09,
  },
  {
    age: 82,
    male: 7.26,
    female: 8.52,
  },
  {
    age: 83,
    male: 6.79,
    female: 7.98,
  },
  {
    age: 84,
    male: 6.33,
    female: 7.45,
  },
  {
    age: 85,
    male: 5.89,
    female: 6.95,
  },
  {
    age: 86,
    male: 5.48,
    female: 6.47,
  },
  {
    age: 87,
    male: 5.08,
    female: 6.01,
  },
  {
    age: 88,
    male: 4.71,
    female: 5.57,
  },
  {
    age: 89,
    male: 4.37,
    female: 5.16,
  },
  {
    age: 90,
    male: 4.05,
    female: 4.78,
  },
  {
    age: 91,
    male: 3.75,
    female: 4.43,
  },
  {
    age: 92,
    male: 3.48,
    female: 4.11,
  },
  {
    age: 93,
    male: 3.23,
    female: 3.81,
  },
  {
    age: 94,
    male: 3.01,
    female: 3.55,
  },
  {
    age: 95,
    male: 2.81,
    female: 3.31,
  },
  {
    age: 96,
    male: 2.64,
    female: 3.09,
  },
  {
    age: 97,
    male: 2.49,
    female: 2.9,
  },
  {
    age: 98,
    male: 2.36,
    female: 2.73,
  },
  {
    age: 99,
    male: 2.24,
    female: 2.58,
  },
  {
    age: 100,
    male: 2.12,
    female: 2.42,
  },
  {
    age: 101,
    male: 2.01,
    female: 2.28,
  },
  {
    age: 102,
    male: 1.9,
    female: 2.14,
  },
  {
    age: 103,
    male: 1.8,
    female: 2.01,
  },
  {
    age: 104,
    male: 1.7,
    female: 1.88,
  },
  {
    age: 105,
    male: 1.6,
    female: 1.76,
  },
  {
    age: 106,
    male: 1.51,
    female: 1.65,
  },
  {
    age: 107,
    male: 1.42,
    female: 1.54,
  },
  {
    age: 108,
    male: 1.34,
    female: 1.44,
  },
  {
    age: 109,
    male: 1.26,
    female: 1.34,
  },
  {
    age: 110,
    male: 1.18,
    female: 1.24,
  },
  {
    age: 111,
    male: 1.1,
    female: 1.15,
  },
  {
    age: 112,
    male: 1.03,
    female: 1.06,
  },
]
