import { useContext } from 'react'
import { LifeExpectancy } from '../../App.js'
import { dateOfDeath } from '../../utils/utils.js'
export default function LifeEstimate({ birthdate }) {
  const lifespan = useContext(LifeExpectancy)
  const death = dateOfDeath(birthdate, lifespan).toString()
  return (
    <h2 className="text-gray-700">
      Based on the life expectancy in the US, you will live until {death}.
    </h2>
  )
}
