import { useEffect, useState } from 'react'
import { validateBirthdate } from '../utils/utils.js'
export default function Home({ handleGoClick }) {
  const [hasError, setHasError] = useState(false)
  const [bday, setBday] = useState('')

  const handleClick = (bday) => {
    if (validateBirthdate(bday)) {
      handleGoClick(bday)
      setHasError(false)
    } else {
      setHasError(true)
    }
  }

  useEffect(() => {
    setBday(document.getElementById('date'))
  }, [])

  return (
    <main>
      <div
        className="min-h-screen flex items-center justify-center"
        style={{
          backgroundImage: 'url(/images/backgrounds/road-through-forrest.jpg)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="w-full max-w-xs mb-10">
          <img className="" src="/images/ourlifecalendar.png" alt="" />

          <div className="pt-10 pb-10">
            <label
              htmlFor="date"
              className="w-64 flex justify-center text-lg font-large text-gray-200"
            >
              When is your Birthdate?
            </label>
            <div className="mt-1 relative flex justify-center">
              <input
                type="date"
                name="date"
                id="date"
                min="1922-01-01"
                className="w-64 shadow-sm focus:ring-green-500 focus:border-green-500 block pr-2 sm:text-sm border-gray-300 rounded-md"
              />
              <button
                type="button"
                onClick={(e) => {
                  handleClick(bday.value)
                }}
                className="inline-flex items-center ml-3 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Go
              </button>
            </div>

            {hasError ? (
              <p className="mt-2 mb-2 flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm">
                Please enter a valid birthdate
              </p>
            ) : (
              ''
            )}
          </div>

          <div className="block w-full max-w-xs text-white text-center pt-6">
            <div className="block text-white text-3xl mb-10">
              What is Our Life Calendar?
            </div>
            <div className="block text-white text-xl mb-2">Learn more</div>
            <svg
              className="fill-current text-white inline-block h-10 w-10"
              viewBox="0 0 32 32"
            >
              <path d="M14.496 5.975l-.001 14.287-6.366-6.367L6 16.021l10.003 10.004L26 16.029 23.871 13.9l-6.366 6.368V5.977z" />
            </svg>
          </div>
        </div>
      </div>
      <div
        className="min-h-screen flex items-center justify-center"
        style={{
          backgroundImage: 'url(/images/backgrounds/winding-road.jpg)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      ></div>
    </main>
  )
}
