import { ageInWeeks } from '../../utils/utils.js'
import NumberFormat from 'react-number-format'
import { useContext } from 'react'
import { weeksUntilDeath } from '../../utils/utils.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { LifeExpectancy } from '../../App.js'
import LifeEstimate from './LifeEstimate.js'

export default function Weeks({ birthdate }) {
  const lifespan = useContext(LifeExpectancy)
  const weeksToLive = weeksUntilDeath(birthdate, lifespan)
  const weeksLived = ageInWeeks(birthdate)
  return (
    <>
      <div className="">
        <div className="flex">
          <LifeEstimate birthdate={birthdate} />
        </div>
        <div className="flex">
          <h2 className="text-gray-700">
            You've lived
            <NumberFormat
              value={weeksLived}
              displayType="text"
              thousandSeparator={true}
              suffix=" "
              prefix=" "
            />
            Weeks so far.
          </h2>
        </div>
        <div className="flex">
          <h2>
            <NumberFormat
              value={weeksToLive}
              displayType="text"
              thousandSeparator={true}
              suffix=" "
              prefix=" "
            />
            Weeks yet to live; so make the most of it!
          </h2>
        </div>
        <div className="">
          <FontAwesomeIcon
            icon={solid('star')}
            className="mr-1"
            size="sm"
            style={{ color: 'tomato' }}
          />
          {Array.from(Array(weeksLived - 1)).map((icon, index) => (
            <FontAwesomeIcon
              icon={solid('square-full')}
              size="sm"
              key={index}
              className="mr-1 text-gray-300"
            />
          ))}
          <FontAwesomeIcon
            icon={solid('gift')}
            className="mr-1"
            size="sm"
            style={{ color: 'tomato' }}
          />
          {Array.from(Array(weeksToLive - 2)).map((icon, index) => (
            <FontAwesomeIcon
              icon={solid('square-full')}
              className="mr-1"
              size="sm"
              key={index}
              style={{ color: 'tomato' }}
            />
          ))}
          <FontAwesomeIcon
            icon={regular('face-dizzy')}
            className="mr-1"
            size="lg"
            style={{ color: 'tomato' }}
          />
        </div>
      </div>
    </>
  )
}
